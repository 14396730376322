import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { Buydata, VerifyPin } from '../services/service'
import Loader from '../components/Loader'

const Data = () => {
    const navigation = useNavigate()
    const { transInfo } = useSelector((state) => state.transInfo)
    const { user, charges } = useSelector((state) => state.user)
    const [networks, setNetwork] = useState([])
    const [phone, setPhone] = useState("")
    const [loading, setLoading] = useState(false)
    const [upper, setUpper] = useState(transInfo?.defaultInfo.Dataplans)
    const [selectedNetwork, setSelectedNetwork] = useState(null)
    const [seletedData, setSeletedData] = useState(null)
    const [amountCharge, setAmountCharge] = useState(0);


    useEffect(() => {
        if (user?.role === 'admin') {
            setAmountCharge(charges?.admin)
        }
        if (user?.role === 'seller') {
            setAmountCharge(charges?.seller)
        }
        if (user?.role === 'client') {
            setAmountCharge(charges?.client)
        }
        getNetwork()
    }, [])


    const getNetwork = () => {
        if (upper === null || upper === undefined) {
            return;
        }

        let mtn = { id: 1, name: Object.keys(upper)[0], logo: 'https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png', datas: upper[Object.keys(upper)[0]]["SME"] }

        let glo = { id: 2, name: Object.keys(upper)[1], logo: 'https://www.loanspot.ng/wp-content/uploads/2021/07/glo-logo.png', datas: upper[Object.keys(upper)[1]]["ALL"] }

        let airtel = upper[Object.keys(upper)[2]]["ALL"]

        let filterAirtel = airtel?.filter((er) => er.plan_type === "CORPORATE GIFTING");

        let newAirtel = { id: 3, name: Object.keys(upper)[2], logo: 'https://seeklogo.com/images/A/airtel-logo-439F62AEA0-seeklogo.com.png', datas: filterAirtel }
        let mobile = { id: 4, name: Object.keys(upper)[3], logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4rGH5NF7jXiew2ow2VbiqiiD54t3rqkTtRSBVVhS-VA&s', datas: upper[Object.keys(upper)[3]]["ALL"] }
        let myNet = [];

        myNet.push(mtn)
        myNet.push(glo)
        myNet.push(mobile)
        myNet.push(newAirtel)
        setNetwork(myNet)
    }

    const formatInput = (event) => {
        let value = event.replace(/\D/g, '');

        if (value.length > 3 && value.length < 12) {
            value = value.replace(/(\d{3})(\d{0,3})/, '$1$2');
        } else if (value.length >= 7) {
            value = value.replace(/(\d{3})(\d{0,3})/, '$1$2');
        }

        setPhone(value);
    };

    const handleSelectNetwork = (data) => {
        setSelectedNetwork(data)
    }
    const handlePay = async () => {
        setLoading(true)
        if (!phone) {
            return toast.error("Phone number can not be empty")
        }
        if (!seletedData) {
            return toast.error("Please select the network / plan provider")
        }
        let model = {
            email: user?.email,
            network: seletedData?.network,
            mobile_number: phone,
            plan: seletedData?.dataplan_id,
            Ported_number: true,
            price: seletedData?.plan_amount,
            price_charged: parseInt(seletedData?.plan_amount) + amountCharge
        }

        let qus = prompt("Please enter your pin:")
        let verPass = {
            email: user?.email,
            pin: qus
        }
        // VerifyPassword(verPass).then((er) => {
        //     if (er.data.success) {
        //         Buydata(model).then((er) => {
        //             // console.log(er)
        //             if (er.data.success) {
        //                 toast.success("This number has been credited")
        //                 navigation('/')
        //             }

        //         })
        //             .catch(err => {
        //                 toast.error(err.response.data.message)
        //                 console.log(err)
        //             })

        //     }
        // })

        await VerifyPin(verPass, user?.token).then((el) => {
            setLoading(false)
            console.log(el.data)
            if (el.data.success) {
                toast.success(el.data.message)
                Buydata(model).then((er) => {
                    // console.log(er)
                    if (er.data.success) {
                        toast.success("This number has been credited")
                        navigation('/')
                    }

                })
                    .catch(err => {
                        toast.error(err.response.data.message)
                        console.log(err)
                    })
            }
        }).catch(err => {
            setLoading(false)
            toast.error(err.response.data.message)
        })
    }

    const handleDataSelect = (item) => {
        // console.log(item)
        setSeletedData(item)
    }



    return (
        <>
            <div className="header is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <Link to={'/'} className="back-btn"> <i className="icon-left"></i> </Link>
                        <h3>Mobile Data</h3>
                    </div>
                </div>
            </div>
            <div id="app-wrap">
                <div className="bill-content">
                    <div className="tf-container">
                        <div>
                            <h3 className="fw_6 mt-3">Network</h3>
                            <div className="input-field d-flex mt-3">

                                <img src={selectedNetwork?.logo} alt="" style={{ height: 20, width: 30, marginRight: 5, border: 'none' }} />

                                <input required className="search-field value_input" placeholder="Phone Number" onChange={(e) => formatInput(e.target.value)} value={phone} type="text" maxLength="11" />

                                <span className="icon-clear" />
                            </div>
                            <div className="tf-spacing-16" />

                            <ul className="mt-3 d-flex" style={{ justifyContent: 'center', justifyItems: 'center' }}>
                                {networks?.map((el, i) => (
                                    <li key={i} onClick={() => handleSelectNetwork(el)} style={{ marginRight: 10, }} className='mr-4'>
                                        <div className="logo">
                                            <img src={el?.logo} alt="image" style={{ height: 38 }} />
                                        </div>
                                    </li>
                                ))}
                            </ul>

                            <h3 className="fw_6 d-flex justify-content-between mt-5">List of services </h3>
                            <ul className="bank-box">
                                {selectedNetwork?.datas?.map((el, index) => (
                                    <li key={index} onClick={() => handleDataSelect(el)} >
                                        {el?.id === seletedData?.id ? (<span>✅</span>) : null}
                                        <a className="bank-list text-center">
                                            <b>{el?.plan}</b>
                                            {el?.month_validate} <br />
                                            ₦{new Intl.NumberFormat().format(parseInt(el?.plan_amount) + amountCharge)}
                                        </a>
                                    </li>
                                ))}

                            </ul>

                            <div className="row my-3">
                                {/* buy button */}
                                <div className="">
                                    <div className="tf-container">
                                        <a href="#" onClick={handlePay} className="tf-btn accent large">{loading ? "Loading..." : "Purchase"}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    )
}

export default Data