import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { Buyairtime, VerifyPassword, VerifyPin } from '../services/service'
import Loader from '../components/Loader'

const Airtime = () => {
    const navigation = useNavigate();
    const { transInfo } = useSelector((state) => state.transInfo)
    const { user } = useSelector((state) => state.user)
    const [networks, setNetwork] = useState([])
    const [amount, setAmount] = useState("")
    const [phone, setPhone] = useState("")
    const [loading, setLoading] = useState(false)
    const [upper, setUpper] = useState(transInfo.defaultInfo?.percentage)
    useEffect(() => {
        getNetwork()
    }, [])

    const getNetwork = () => {
        if (upper === null || upper === undefined) {
            return;
        }
        let mtn = { id: 1, name: Object.keys(upper)[0], logo: 'https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png' }
        let glo = { id: 2, name: Object.keys(upper)[1], logo: 'https://www.loanspot.ng/wp-content/uploads/2021/07/glo-logo.png' }
        let airtel = { id: 3, name: Object.keys(upper)[2], logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4rGH5NF7jXiew2ow2VbiqiiD54t3rqkTtRSBVVhS-VA&s' }
        let mobile = { id: 4, name: Object.keys(upper)[3], logo: 'https://seeklogo.com/images/A/airtel-logo-439F62AEA0-seeklogo.com.png' }
        let myNet = [];
        myNet.push(mtn)
        myNet.push(glo)
        myNet.push(mobile)
        myNet.push(airtel)
        setNetwork(myNet)
    }

    const formatInput = (event) => {
        let value = event.replace(/\D/g, '');

        if (value.length > 3 && value.length < 12) {
            value = value.replace(/(\d{3})(\d{0,3})/, '$1$2');
        } else if (value.length >= 7) {
            value = value.replace(/(\d{3})(\d{0,3})/, '$1$2');
        }

        setPhone(value);
    };
    const [selectedNetwork, setSelectedNetwork] = useState(null)
    const handleSelectNetwork = (data) => {
        setSelectedNetwork(data)
    }
    const handlePay = async () => {
        setLoading(true)

        if (!phone || amount === "" || parseInt(amount) < 10) {
            return toast.error("Phone number or Amount can not be empty")
        }
        if (!selectedNetwork) {
            return toast.error("Please select the network provider")
        }
        let model = {
            email: user?.email,
            network: selectedNetwork.id,
            amount: amount,
            mobile_number: phone
        }

        let qus = prompt("Please enter your pin:")
        let verPass = {
            email: user?.email,
            pin: qus
        }


        await VerifyPin(verPass, user?.token).then((el) => {
            setLoading(false)
            if (el.data.success) {
                toast.success(el.data.message)
                Buyairtime(model).then((er) => {
                    // console.log(er)
                    if (er.data.success) {
                        toast.success("Your Phone has been credited")
                        navigation('/')
                    }

                })
                    .catch(err => {
                        toast.error(err.response.data.message)
                        console.log(err)
                    })
            }
        }).catch(err => {
            setLoading(false)
            toast.error(err)
        })


    }


    return (
        <>
            <div className="header is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <Link to={'/'} className="back-btn"> <i className="icon-left"></i> </Link>
                        <h3>Airtime</h3>
                    </div>
                </div>
            </div>
            <div id="app-wrap">
                <div className="bill-content">
                    <div className="tf-container">
                        <div>
                            <h3 className="fw_6 mt-3">Network</h3>
                            <div className="input-field d-flex mt-3">

                                <img src={selectedNetwork?.logo} alt="" style={{ height: 20, width: 30, marginRight: 5, border: 'none' }} />
                                {/* <input required className="search-field value_input" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)} type="text" /> */}
                                <input required className="search-field value_input" placeholder="Phone Number" onChange={(e) => formatInput(e.target.value)} value={phone} type="text" maxLength="11" />

                                <span className="icon-clear" />
                            </div>
                            <div className="tf-spacing-16" />
                            <div className="tf-form">
                                <div className="group-input input-field input-money">
                                    <label>Amount</label>
                                    <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} required className="search-field value_input st1" />
                                    <span className="icon-clear" />
                                </div>
                            </div>
                            <ul className="mt-3 box-outstanding-service">
                                {networks?.map((el, i) => (
                                    <li key={i} onClick={() => handleSelectNetwork(el)}>
                                        <div className="logo">
                                            <img src={el?.logo} alt="image" style={{ height: 28 }} />
                                        </div>
                                        <span>{el.name}</span>
                                    </li>
                                ))}
                            </ul>
                            <div className="row my-3">
                                {/* buy button */}
                                <div className="">
                                    <div className="tf-container">
                                        {/* {loading ? <Loader /> : (<a href="#" onClick={handlePay} className="tf-btn accent large">Purchase</a>)} */}
                                        <a href="#" onClick={handlePay} className="tf-btn accent large">{loading ? "Loading..." : "Purchase"}</a>
                                    </div>
                                </div>
                            </div>
                            <h3 className="fw_6 d-flex justify-content-between mt-5">List of services </h3>
                            <ul className="box-service mt-3">

                                <li>
                                    <a href="#">
                                        <div className="icon-box bg_color_8">
                                            {/* icon */}
                                        </div>
                                        {/* Market */}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    )
}

export default Airtime