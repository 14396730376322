import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../components/Loader';

const Settings = () => {
    const navigation = useNavigate();
    const [updatePrice, setupdatePrice] = useState(false)
    // const [admin, setAdmin] = useState(0)
    // const [seller, setSeller] = useState(0)
    // const [client, setClient] = useState(0)
    const [loading, setLoading] = useState(false)

    const handleLogout = () => {
        window.localStorage.removeItem('user');
        window.localStorage.clear();
        navigation('/login');
    }

    // useEffect(() => {
    //     getCharges()
    // }, [])

    // const getCharges = async () => {
    //     await GetPrices().then((res) => {
    //         console.log(res.data.prices[0].admin)
    //         setAdmin(res.data.prices[0].admin)
    //         setClient(res.data.prices[0].client)
    //         setSeller(res.data.prices[0].seller)
    //     }).catch(err => {
    //         toast.error(err.message)
    //     })
    // }


    const handleChangePrice = async () => {
        setLoading(true)
        let model = {
            // admin: parseInt(admin),
            // seller: parseInt(seller),
            // client: parseInt(client)
        }

        // await PostUpdatePrice(model).then((el) => {
        //     setLoading(false)
        //     if (el.data.status) {
        //         toast.success("Updated successfully")
        //         window.location.reload();
        //     }
        //     console.log(el.data)
        // }).catch(err => {
        //     setLoading(false)
        //     console.log(err.message)
        //     toast.error(err.message)
        // })
    }

    return (
        <div>
            {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>}
            <div className="header mb-1 is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <Link to={'/admin'} className="back-btn"> <i className="icon-left" /> </Link>
                        <h3>Settings</h3>
                    </div>
                </div>
            </div>
            <div id="app-wrap">

                <div className="container">
                    <ul className="mt-1">
                        <li>
                            <a className="list-profile outline" style={{ cursor: 'pointer' }} onClick={() => setupdatePrice(!updatePrice)}>
                                <p>Update Price Charges</p>
                                <span><i className="icon-right" /></span>
                            </a>


                        </li>
                    </ul>
                </div>
                <ul className="box-settings-profile mt-1 mb-8">
                    <hr />
                    <li>
                        <a className="list-setting-profile" onClick={handleLogout}>
                            <span className="icon icon-logout" />
                            <p>Logout</p>
                            <i className="icon-right" />
                        </a>
                    </li>
                </ul>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Settings