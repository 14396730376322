import React, { useEffect, useState } from 'react'
import { GetUsers, UpdateUser, UpdateWallet } from '../../services/admin_service'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

const Users = () => {
    const [allUsers, setAllUsers] = useState(null)
    const [openRole, setOpenRole] = useState("")
    const [openAmount, setOpenAmount] = useState("")
    const [amount, setAmount] = useState("")
    const [role, setRole] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getAllUsers()
    }, [])

    const getAllUsers = async () => {
        await GetUsers().then((el) => {
            setAllUsers(el.data.returnObj)
            // console.log(el.data)
        }).catch(err => {
            console.log(err.message)
            toast.error(err.message)
        })
    }

    const handleUpdateAmount = async (item) => {
        console.log(item, amount)
        setLoading(true)
        let model = {
            email: item,
            balance: parseInt(amount)
        }
        await UpdateWallet(model)
            .then((result) => {
                setLoading(false)
                toast.success(result.data.message)
                setOpenAmount("")
                getAllUsers()
            }).catch(err => {
                setLoading(false)
                console.log(err.message)
                toast.error(err.message)
            })
    }
    const handleUpdateRole = async (item) => {
        setLoading(true)
        let model = {
            email: item,
            role: role
        }
        await UpdateUser(model)
            .then((result) => {
                setLoading(false)
                toast.success(result.data.message)
                setOpenRole("")
                getAllUsers()
            }).catch(err => {
                setLoading(false)
                console.log(err.message)
                toast.error(err.message)
            })

    }
    return (
        <div className="panel-box panel-up">
            <div className="header-transfer header-st2">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex align-items-center">
                        <Link to={'/admin'} className="clear-panel"><i className="icon-left on_surface_color" /></Link>
                        <h3 className>All Users</h3>
                    </div>
                </div>
            </div>
            <div className="wrap-transfer-friends mt-3">
                <div className="tf-container">

                    <div style={{ marginTop: 50, marginBottom: 50 }}>

                        <div className="mt-4">
                            <ul className="tabs-list-item" style={{ display: 'block' }}>

                                {allUsers?.map((el, index) => (
                                    <li key={index}>
                                        <a href="#" className="">
                                            <ul className="inner">
                                                <li className="user">
                                                    <img src="https://static-00.iconduck.com/assets.00/user-icon-2048x2048-ihoxz4vq.png" style={{ height: 30, width: 30 }} alt="image" />
                                                </li>
                                                <li className="info">
                                                    <h4>{el?.name}</h4>
                                                    <p>{el?.phone} {"."} {el?.email}</p>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <p>{el?.role}</p>
                                                        <span>₦{el?.walletData[0]?.balance}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </a>
                                        <div className="d-flex mb-2">
                                            <a onClick={() => setOpenAmount(el.email)} className="btn btn-secondary btn-sm ml-2">Top Up</a>
                                            <a onClick={() => setOpenRole(el.email)} className="btn btn-warning btn-sm">Change Role</a>
                                        </div>
                                        {openAmount === el.email && (<div>
                                            <input type='number' onChange={(e) => setAmount(e.target.value)} placeholder='amount' />
                                            <button type='button' className='btn btn-sm btn-success' onClick={() => handleUpdateAmount(el.email)}>Add</button>

                                        </div>)}
                                        {openRole === el.email && (<>
                                            <select onChange={(e) => setRole(e.target.value)}>
                                                <option value="">Select</option>
                                                <option value="seller">Seller</option>
                                                <option value="client">Client</option>
                                                <option value="admin">Admin</option>
                                            </select>
                                            <button type='button' className='btn' onClick={() => handleUpdateRole(el.email)}>Update</button>
                                        </>)}
                                        <hr />
                                    </li>
                                ))}
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Users