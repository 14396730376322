import React from 'react'

const BankTransfer = () => {
    return (
        <div>
            <div className="app-header st1">
                <div className="tf-container">
                    <div className="tf-topbar d-flex justify-content-center align-items-center">
                        <a href="/" className="back-btn"><i className="icon-left white_color" /></a>
                        <h3 className="white_color">Bank Transfer</h3>
                    </div>
                </div>
            </div>

            <div className="transfer-card">
                <div className="tf-container">
                    <div className="tf-card-block d-flex align-items-center justify-content-between">
                        <div className="inner d-flex align-items-center">
                            <i className="logo icon-wallet-filled-money-tool" />
                            <div className="content">
                                <h4> <a className="fw_6">Zenith Bank</a></h4>
                                <p><a href="tel:2910927772"> 2910927772</a></p>
                                <p>Adeoye Temitayo</p>
                            </div>
                        </div>
                    </div>
                    <div className="tf-card-block d-flex align-items-center justify-content-between">
                        <div className="inner d-flex align-items-center">
                            <i className="logo icon-wallet-filled-money-tool" />
                            <div className="content">
                                <h4><a className="fw_6">OPAY Microfinace Bank</a></h4>
                                <p><a href="tel:8167927876">8167927876</a></p>
                                <p>Adeoye Temitayo</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default BankTransfer