import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { GetVerifyCableCard, PayCable } from '../services/ExternalService';
import { DeductBalance, GetBalance } from '../services/service';
import { useSelector } from 'react-redux';

const TVDetails = () => {
    const location = useLocation()
    const { user } = useSelector((state) => state.user)
    const [balance, setBalance] = useState(0)
    const [cardNo, setCardNo] = useState("")
    const [loader, setLoader] = useState(false)
    const [customerInfo, setCustomerInfo] = useState(null)
    const { model, charge } = location.state;

    useEffect(() => {
        getBalanceInfo();
    }, [])

    const handleVerify = () => {
        setLoader(true)
        GetVerifyCableCard(cardNo, model?.service_id, model?.variation_id)
            .then(response => response.json())
            .then(data => {
                console.log("response", data)
                setLoader(false)
                if (data?.code === "success") {
                    setCustomerInfo(data?.data)
                    toast.success(data.message)
                } else {
                    toast.error(data.message)
                }

            })
            .catch(error => {
                toast.error(error)
                setLoader(false)
                console.error(error)
            });
    }

    const getBalanceInfo = async () => {
        let model = {
            email: user?.email
        }
        setLoader(true)
        await GetBalance(model, user?.token).then((el) => {
            setLoader(false)
            console.log(el.data.balance.balance)
            if (el.data.success) {
                setBalance(el.data.balance?.balance)
            }
        }).catch(err => {
            console.log(err)
            setLoader(false)
        })
    }

    const handleSubscribe = () => {
        if (balance < parseInt(model?.price) + charge) {
            return toast.error("Your Balance is less than the service you are buying")
        }
        if (window.confirm('Are you sure you want to continue payment?')) {
            PayCable(cardNo, model?.service_id, model?.variation_id)
                .then(response => response.json())
                .then(data => {
                    console.log("response", data)
                    //parseInt(model?.price) + charge
                    if (data?.code === "success") {
                        Deduct();
                        setLoader(false)
                        toast.success(data.message)
                        window.location.href = "/"
                    } else {
                        toast.error(data.message)
                    }

                })
                .catch(error => {
                    toast.error(error)
                    setLoader(false)
                    console.error(error)
                });
        }
    }

    const Deduct = () => {
        let item = {
            email: user?.email,
            price: parseInt(model?.price) + charge,
            whatfor: "TV"
        }
        DeductBalance(item).then((er) => {
            console.log(er.data)
        }).catch(err => {
            console.log(err)
        })
    }

    // { email, price, whatfor}

    return (
        <div style={{ flex: 1, marginBottom: 100 }}>
            <div className="app-header st1">
                <div className="tf-container">
                    <div className="tf-topbar d-flex justify-content-center align-items-center">
                        <a href="/" className="back-btn"><i className="icon-left white_color" /></a>
                        <h3 className="white_color">{model?.name}</h3>
                    </div>
                </div>
            </div>
            <div className="card-secton topup-content">
                <div className="tf-container">
                    <div className="tf-balance-box" style={{ marginBottom: 0 }}>

                        <div className="tf-spacing-16" />
                        <div className="tf-form">
                            <div className="group-input input-field input-money">
                                <label>Verify Card Number</label>
                                <input type="number" onChange={(e) => setCardNo(e.target.value)} className="search-field value_input st1" />
                                <span className="icon-clear" />
                                <a className="tf-btn accent large mt-3" onClick={handleVerify}>{loader ? 'Loading...' : 'Verify Number'}</a>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <hr />
            <div className="container">
                <h3 className="fw_6 d-flex justify-content-between mt-5">Customer Information <strong>{model?.name}</strong></h3>
                <ul className="mt-3 mb-5">
                    <li className="list-card-invoice">
                        <div className="logo">
                            <span>✅</span>
                        </div>
                        <div className="content-right">
                            <h4>
                                <small>Customer Name:</small> {customerInfo?.customer_name}
                            </h4>
                            <p><small>Status:</small> {customerInfo?.decoder_status}</p>
                            <p><small>Due Date:</small> {customerInfo?.decoder_due_date}</p>
                            <p>
                                <small>Price:</small> ₦ {parseInt(model?.price) + charge}
                            </p>
                        </div>
                    </li>

                </ul>
            </div>
            <div className="amount-money mt-2">
                <div className="mt-3">
                    {customerInfo !== null && (<a className="tf-btn accent large mt-3" style={{ margin: 'auto', width: '50%', backgroundColor: 'green', border: 'none' }} onClick={handleSubscribe}>Subscribe Now!</a>)}
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default TVDetails