import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';


const Footer = () => {
    const { user } = useSelector((state) => state.user)
    return (
        <>
            {user ? (<div className="bottom-navigation-bar">
                <div className="tf-container">
                    <ul className="tf-navigation-bar">
                        <li className="active"><a className="fw_6 d-flex justify-content-center align-items-center flex-column" href="/"><i className="icon-home2" /> Home</a> </li>
                        <li><a className="fw_4 d-flex justify-content-center align-items-center flex-column" href="/history"><i className="icon-history" /> </a> </li>
                        <li><a className="fw_4 d-flex justify-content-center align-items-center flex-column" href="/profile"><i className="icon-user-outline" /> Profile</a> </li>
                    </ul>
                    {/* <span class="line"></span> */}
                </div>
            </div>) : null}
        </>
    )
}

export default Footer