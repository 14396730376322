import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GetAdminBalance } from '../../services/ExternalService'
import { Link } from 'react-router-dom'

const AdminTopup = () => {
    const { user } = useSelector((state) => state.user)
    const [balance, setBalance] = useState("")
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        getBalances()
    }, [])

    const getBalances = () => {
        setLoader(true)
        GetAdminBalance()
            .then(response => response.json())
            .then(data => {
                setLoader(false)
                // console.log("response", data)
                if (data.code === "success") {
                    setBalance(data.data.balance)
                }
            })
            .catch(error => {
                setLoader(false)
                console.error(error)
            });
    }

    return (
        <div style={{ marginBottom: 60 }}>
            <div className="app-header st1">
                <div className="tf-container">
                    <div className="tf-topbar d-flex justify-content-center align-items-center mb-3">
                        <Link to={"/admin"} className="back-btn"><i className="icon-left white_color" /></Link>
                        <h3 className="white_color">Banks To Topup on Ebills</h3>

                    </div>
                    <p className='mx-auto text-center text-white'>Current Balance: {loader && <span>loading...</span>}<span style={{ fontSize: 20 }}>NGN {balance}</span></p>
                </div>
            </div>

            <div className="transfer-card">
                <div className="tf-container">

                    <div className="tf-card-block d-flex align-items-center justify-content-between mt-5">
                        <div className="inner d-flex align-items-center">
                            <i className="logo icon-wallet-filled-money-tool" />
                            <div className="content">
                                <h4> <a className="fw_6">Bank Name: Wema bank</a></h4>
                                <p>Accont Name: MONNIFY / eBills.ng-SEU</p>
                                <p style={{ fontSize: 24, fontWeight: '700' }}><a href="tel:9513729022">9513729022</a></p>
                            </div>
                        </div>
                    </div>

                    <div className="tf-card-block d-flex align-items-center justify-content-between mt-5">
                        <div className="inner d-flex align-items-center">
                            <i className="logo icon-wallet-filled-money-tool" />
                            <div className="content">
                                <h4> <a className="fw_6">Bank Name: Moniepoint Microfinance Bank</a></h4>
                                <p>Accont Name: eBills.ng-SEU</p>
                                <p style={{ fontSize: 24, fontWeight: '700' }}><a href="tel:6416570998">6416570998</a></p>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default AdminTopup