// export const BASE_URL = "http://localhost:5002/api/v1/"
export const BASE_URL = "https://rechup.vercel.app/api/v1/"
export const External_URL = "https://ebills.ng/wp-json/api/v1/"
export const USERNAME = "seunfavour"
export const PASSWORD = "9@XNjb3pNf3PkPu"

export function getUser() {
    let user = localStorage.getItem('user');
    user = user ? JSON.parse(user) : null;
    return user;
} 