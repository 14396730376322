import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'



const initialState = {

    loading: false,
    user: window.localStorage.getItem('user') !== null ? JSON.parse(window.localStorage.getItem('user')) : null,
    charges: window.localStorage.getItem('charges') !== null ? JSON.parse(window.localStorage.getItem('charges')) : null,

};


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginUser: (state, action) => {
            state.user = action.payload;
        },
        setCharges: (state, action) => {
            state.charges = action.payload;
        },
        logout: (state) => {
            state.user = null;
        },

    }
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(loginUser.pending, (state) => {
    //             state.loading = true;
    //             state.user = null;
    //             state.error = null;
    //         })
    //         .addCase(loginUser.fulfilled, (state, action) => {
    //             state.loading = false;
    //             state.user = action.payload.response;
    //             state.charges = action.payload.charge;
    //             state.error = null;
    //         })
    //         .addCase(loginUser.rejected, (state, action) => {
    //             state.loading = false;
    //             state.user = null;
    //             state.error = action.error.message;
    //         })
    // }
})

export const { loginUser, setCharges, logout } = userSlice.actions;
export default userSlice.reducer;