import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const AdminTransfer = () => {
    const { transInfo } = useSelector((state) => state.transInfo)
    const { user } = useSelector((state) => state.user)
    const [banks, setBanks] = useState(transInfo.defaultInfo.userObj.bank_accounts)

    // console.log(transInfo.defaultInfo.userObj.bank_accounts)
    //
    useEffect(() => {
    }, [])

    if (user?.role !== 'admin') {
        window.location.href = '/login';
        return
    }
    return (
        <div style={{ marginBottom: 60 }}>
            <div className="app-header st1">
                <div className="tf-container">
                    <div className="tf-topbar d-flex justify-content-center align-items-center mb-5">
                        <Link to={"/admin"} className="back-btn"><i className="icon-left white_color" /></Link>
                        <h3 className="white_color">Banks To Topup on Wakasub</h3>
                    </div>
                    <p className='mx-auto text-center text-white'>Current Balance: <span style={{ fontSize: 20 }}>NGN {transInfo.defaultInfo?.userObj.Account_Balance}</span></p>
                </div>
            </div>


            <div className="transfer-card">
                <div className="tf-container">

                    {banks?.map((el, index) => (
                        <div className="tf-card-block d-flex align-items-center justify-content-between" key={index}>
                            <div className="inner d-flex align-items-center">
                                <i className="logo icon-wallet-filled-money-tool" />
                                <div className="content">
                                    <h4> <a className="fw_6">{el?.bankName}</a></h4>
                                    <p><a href="tel:2910927772"> {el?.accountName}</a></p>
                                    <p>{el?.accountNumber}</p>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>

            </div>
        </div>
    )
}

export default AdminTransfer