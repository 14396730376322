import { configureStore } from "@reduxjs/toolkit"
import userReducer from './userSlice';
import transReducer from './transSlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        transInfo: transReducer,
    }
})

export default store;