import axios from "axios";
import { BASE_URL, getUser } from "../config";

// const user = getUser();
const user = JSON.parse(window.localStorage.getItem('user'));
const header = {
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + user?.token
}

// console.log(user)

export const GetAdInfo = async (model) => {
    return await axios.get(`${BASE_URL}info/user-info`, {
        "headers": {
            'Content-Type': 'application/json'
        }
    })
}
export const LoginUser = async (model) => {
    return await axios.post(`${BASE_URL}auth/login`, model, {
        "headers": {
            'Content-Type': 'application/json'
        }
    })
}
export const RegisterUser = async (model) => {
    return await axios.post(`${BASE_URL}auth/register`, model, {
        "headers": {
            'Content-Type': 'application/json'
        }
    })
}
export const Forgot_Password = async (model) => {
    return await axios.post(`${BASE_URL}auth/forgot`, model, {
        "headers": {
            'Content-Type': 'application/json'
        }
    })
}
export const VerifyPassword = async (model) => {
    return await axios.post(`${BASE_URL}auth/verify`, model, {
        "headers": {
            'Content-Type': 'application/json'
        }
    })
}
export const ResetPass = async (model) => {
    return await axios.post(`${BASE_URL}auth/passwordreset`, model, {
        "headers": {
            'Content-Type': 'application/json'
        }
    })
}

export const Buyairtime = async (model) => {
    return await axios.post(`${BASE_URL}trans/buyairtime`, model, {
        "headers": header
    })
}
export const Buydata = async (model) => {
    return await axios.post(`${BASE_URL}trans/buydata`, model, {
        "headers": header
    })
}

export const DeductBalance = async (model) => {
    return await axios.post(`${BASE_URL}trans/post/deduct`, model, {
        "headers": header
    })
}

export const PostTransaction = async (model) => {
    return await axios.post(`${BASE_URL}trans/transaction`, model, {
        "headers": header
    })
}
export const GetTransaction = async () => {
    return await axios.get(`${BASE_URL}trans/transaction`, {
        "headers": header
    })
}

export const GetHistory = async () => {
    return await axios.get(`${BASE_URL}trans/history`, {
        "headers": header
    })
}
export const GetBalance = async (model, token) => {
    return await axios.post(`${BASE_URL}trans/balance`, model, {
        "headers": {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        }
    })
}
export const UpdatePin = async (model, token) => {
    return await axios.post(`${BASE_URL}auth/update/pin`, model, {
        "headers": {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        }
    })
}
export const VerifyPin = async (model, token) => {
    return await axios.post(`${BASE_URL}auth/pin`, model, {
        "headers": {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        }
    })
}

export const PostHistory = async (model) => {
    return await axios.post(`${BASE_URL}trans/history`, model, {
        "headers": header
    })
}
export const GetSingleHistory = async (model) => {
    return await axios.post(`${BASE_URL}trans/get/history`, model, {
        "headers": {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + user?.token
        }
    })
}

export const GetTVCable = async () => {
    return await axios.get(`${BASE_URL}trans/get/cables`, {
        "headers": {
            'Content-Type': 'application/json',

        }
    })
}
export const GetElectricBill = async () => {
    return await axios.get(`${BASE_URL}trans/get/electric`, {
        "headers": {
            'Content-Type': 'application/json',

        }
    })
}