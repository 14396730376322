import { TailSpin } from 'react-loader-spinner'

import React from 'react'

const Loader = () => {
    return (
        <TailSpin
            height="30"
            width="30"
            color="#2574f5"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
        />
    )
}

export default Loader