import { External_URL, PASSWORD, USERNAME } from "../config"
import axios from 'axios'

export const GetVerifyCableCard = async (customer_id, service_id, variation_id) => {
    return fetch(`${External_URL}verify-customer?username=${USERNAME}&password=${PASSWORD}&customer_id=${customer_id}&service_id=${service_id}&variation_id=${variation_id}`, {
        method: "GET"
    });
}

export const PayCable = async (customer_id, service_id, variation_id) => {
    return fetch(`${External_URL}tv?username=${USERNAME}&password=${PASSWORD}&service_id=${service_id}&smartcard_number=${customer_id}&variation_id=${variation_id}`, {
        method: "GET"
    });
}

export const PayElectric = async (customer_id, service_id, variation_id, amount) => {
    return fetch(`${External_URL}electricity?username=${USERNAME}&password=${PASSWORD}&phone=08167927876&service_id=${service_id}&meter_number=${customer_id}&variation_id=${variation_id}&amount=${amount}`, {
        method: "GET"
    });
}

export const GetAdminBalance = async () => {
    return fetch(`${External_URL}balance?username=${USERNAME}&password=${PASSWORD}`, {
        method: "GET"
    });
}

