import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    transInfo: {
        balance: 0, //window.localStorage.getItem('balance') !== null ? JSON.parse(window.localStorage.getItem('balance')) : 0,
        defaultInfo: window.localStorage.getItem('userInfo') !== null ? JSON.parse(window.localStorage.getItem('userInfo')) : null
    }
};

const transSlice = createSlice({
    name: "transInfo",
    initialState,
    reducers: {
        getBalance: (state, action) => {
            state.transInfo.balance = action.payload;
        },
        updateBalance: (state, action) => {
            state.transInfo.balance = action.payload;
        },
        getAppInfo: (state, action) => {
            state.transInfo.defaultInfo = action.payload;
        }
    },
});

export const { getAppInfo, getBalance, updateBalance } = transSlice.actions;

export default transSlice.reducer;