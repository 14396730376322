import React, { useEffect } from 'react'
import { useState } from 'react'
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { getUser } from '../config'
import { PostTransaction, PostHistory, GetBalance } from '../services/service';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getBalance, updateBalance } from '../store/transSlice';
import axios from 'axios'
import { usePaystackPayment } from 'react-paystack';



const TopUp = () => {
    const dispatch = useDispatch();
    const [amount, setAmount] = useState("0")
    const { user } = useSelector((state) => state.user)
    const { transInfo } = useSelector((state) => state.transInfo)

    // console.log(transInfo)
    //fluterwaves
    const flutterConfig = {
        public_key: "FLWPUBK-da604577aaf93aec5110621b12e51513-X",
        tx_ref: Date.now(),
        amount: parseInt(amount),
        currency: "NGN",
        payment_options: "card,mobilemoney,ussd",
        customer: {
            email: user?.email,
            phone_number: user?.phone,
            name: user?.name,
        },
        customizations: {
            title: "Topup your account",
            description: "Increase your money on wallet",
            logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
        },
    };


    const handleFlutterPayment = useFlutterwave(flutterConfig);
    const handleChangeAmount = (amm) => {
        setAmount(amm)
    }
    const handleContinue = async (obj) => {
        // if (obj.status === "successful") {
        //     setBalance(obj.amount)
        // }
        let model = {
            status: obj.status,
            name: user?.name,
            email: user?.email,
            phone_number: user?.phone,
            type: "Topup",
            transaction_id: obj.transaction_id,
            tx_ref: obj.tx_ref,
            flw_ref: obj.flw_ref,
            currency: obj.currency,
            amount: obj.amount,
            charged_amount: obj.amount,
            isIncome: true,
            created_at: obj.created_at,
        }
        let history = {
            email: user?.email,
            amount: obj.amount,
            whatFor: "Topup",
            isIncome: true
        }

        if (obj.status === "successful") {
            await PostHistory(history).then((res) => {
                console.log(res.data)
            }).catch((err) => {
                console.log(err)
            })
        }
        await PostTransaction(model).then((er) => {
            if (er.data.success) {
                // setBalance(er.data.balance.balance)
                dispatch(updateBalance(er.data.balance.balance))
            }
            console.log(er)
            toast.success("Transaction was successful")
        }).catch((err) => {
            console.log(err)
            toast.error("Something went wrong")
        })
    }

    //paystack

    // const payWithPaystack = async () => {
    //     const url = "http://localhost:5002/api/v1/trans/paystack"
    //     let form = {
    //         email: user?.email,
    //         amount: amount
    //     }
    //     axios.post(url, form, {
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     }).then((response) => {
    //         if (response.data.status) {
    //             window.location.href = response.data.data.authorization_url

    //             let model = {
    //                 status: response.data.status,
    //                 name: user?.name,
    //                 email: user?.email,
    //                 phone_number: user?.phone,
    //                 type: "Topup",
    //                 transaction_id: response.data.data.reference,
    //                 tx_ref: response.data.data.reference,
    //                 flw_ref: response.data.data.access_code,
    //                 currency: 'NGN',
    //                 amount: parseInt(amount),
    //                 charged_amount: parseInt(amount),
    //                 isIncome: true,
    //                 created_at: Date.now().toString(),
    //             }
    //             let history = {
    //                 email: user?.email,
    //                 amount: parseInt(amount),
    //                 whatFor: "Topup",
    //                 isIncome: true
    //             }

    //             if (response.data.status) {
    //                 PostHistory(history).then((res) => {
    //                     console.log(res.data)
    //                 }).catch((err) => {
    //                     console.log(err)
    //                 })
    //             }
    //             PostTransaction(model).then((er) => {
    //                 if (er.data.success) {
    //                     // setBalance(er.data.balance.balance)
    //                     dispatch(updateBalance(er.data.balance.balance))
    //                 }
    //                 console.log(er)
    //                 toast.success("Transaction was successful")
    //             })
    //         }
    //     }).catch((err) => {
    //         console.log(err)
    //         toast.error("Something went wrong")
    //     })
    // }

    const config = {
        reference: (new Date()).getTime().toString(),
        email: user?.email,
        phone: user?.phone,
        amount: parseInt(amount) * 100,
        publicKey: 'pk_live_297f3418d682235b97e1e286627e582f4d9eace3',
        // publicKey: 'pk_test_58c0839b242f848dd262f2a143c00eba08cc9729',
    };

    const onSuccess = (reference) => {
        let model = {
            status: true,
            name: user?.name,
            email: user?.email,
            phone_number: user?.phone,
            type: "Topup",
            transaction_id: reference.reference,
            tx_ref: reference.trxref,
            flw_ref: reference.transaction,
            currency: "NGN",
            amount: parseInt(amount) * 100,
            charged_amount: parseInt(amount) * 100,
            isIncome: true,
            created_at: (new Date()).getTime().toString(),
        }

        let history = {
            email: user?.email,
            amount: parseInt(amount) * 100,
            whatFor: "Topup",
            isIncome: true
        }

        if (reference.status === "success") {
            PostHistory(history).then((res) => {
                console.log(res.data)
            }).catch((err) => {
                console.log(err)
            })
        }

        PostTransaction(model).then((er) => {

            console.log(er)
            toast.success("Transaction was successful")
        }).catch((err) => {
            console.log(err)
            toast.error("Something went wrong")
        })

        window.location.href = '/'

        // console.log(reference);
    };

    // you can call this function anything
    const onClose = () => {
        console.log('closed')
    }

    const initializePayment = usePaystackPayment(config);

    return (
        <div style={{ flex: 1, marginBottom: 100 }}>
            <div className="app-header st1">
                <div className="tf-container">
                    <div className="tf-topbar d-flex justify-content-center align-items-center">
                        <a href="/" className="back-btn"><i className="icon-left white_color" /></a>
                        <h3 className="white_color">Top Up</h3>
                    </div>
                </div>
            </div>
            <div className="card-secton topup-content">
                <div className="tf-container">
                    <div className="tf-balance-box" style={{ marginBottom: 0 }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <p>Your Balance:</p>
                            {/* <h3>₦{transInfo?.balance}</h3> */}
                        </div>
                        <div className="tf-spacing-16" />
                        <div className="tf-form">
                            <div className="group-input input-field input-money">
                                <label htmlFor>Amount Of Money</label>
                                <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} required className="search-field value_input st1" />
                                <span className="icon-clear" onClick={() => setAmount("0")} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="amount-money mt-2">
                <div className="container">
                    <h3>Amount Money</h3>
                    <ul className="money list-money">
                        <li><a className="tag-money" href="#" onClick={() => handleChangeAmount("500")}>₦500</a> </li>
                        <li><a className="tag-money" href="#" onClick={() => handleChangeAmount("800")}>₦800</a> </li>
                        <li><a className="tag-money" href="#" onClick={() => handleChangeAmount("1000")}>₦1,000</a> </li>
                        <li><a className="tag-money" href="#" onClick={() => handleChangeAmount("2000")}>₦2,000</a> </li>
                        <li><a className="tag-money" href="#" onClick={() => handleChangeAmount("5000")}>₦5,000</a> </li>
                        <li><a className="tag-money" href="#" onClick={() => handleChangeAmount("10000")}>₦10,000</a> </li>
                    </ul>
                </div>

                <div className="mt-3">

                    <div className="tf-container">
                        <a href="#" onClick={() =>
                            handleFlutterPayment({
                                callback: (response) => {
                                    handleContinue(response);
                                    closePaymentModal();
                                },
                                onClose: () => { },
                            })
                        } className="tf-btn accent large">Pay with Flutterwave</a>
                    </div>
                    {/* <a className="tf-btn accent large mt-3" style={{ margin: 'auto', width: '60%', backgroundColor: 'lightgreen', border: 'none' }} onClick={payWithPaystack}>Pay with paystack</a> */}

                    <button className="tf-btn accent large mt-3" style={{ margin: 'auto', width: '60%', backgroundColor: 'lightgreen', border: 'none' }} onClick={() => {
                        initializePayment(onSuccess, onClose)
                    }}>Pay Now</button>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default TopUp