import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from "react-router-dom"
import Home from '../pages/Home'
import Login from '../pages/Login'
import Profile from '../pages/Profile'
import Register from '../pages/Register'
import NotFound from '../pages/NotFound'
import { getUser } from '../config'
import TopUp from '../pages/TopUp'
import Airtime from '../pages/Airtime'
import Data from '../pages/Data'
import BankTransfer from '../pages/BankTransfer'
import TVs from '../pages/TVs'
import Admim from '../pages/admin/Admin'
import Users from '../pages/admin/Users'
import { useSelector } from 'react-redux'
import AdminTransfer from '../pages/admin/AdminTransfer'
import History from '../pages/History'
import PrivateRoute from '../layouts/PrivateRoute'
import AdminPrivateRoute from '../layouts/AdminPrivateRoute'
import ForgotPassword from '../pages/auth/ForgotPassword'
import ResetPassword from '../pages/auth/ResetPassword'
import ConfirmOtp from '../pages/auth/ConfirmOtp'
import TVDetails from '../pages/TVDetails'
import Electricity from '../pages/Electricity'
import AdminTopup from '../pages/admin/AdminTopup'
import AllTransactions from '../pages/admin/AllTransactions'
import Settings from '../pages/admin/Settings'

const Router = () => {
    const { user } = useSelector((state) => state.user)


    return (
        <Routes>
            <Route path='/login' element={<Login />}></Route>
            <Route path='/register' element={<Register />}></Route>
            <Route path='/forgot-password' element={<ForgotPassword />}></Route>
            <Route path='/otp' element={<ConfirmOtp />}></Route>
            <Route path='/reset-password' element={<ResetPassword />}></Route>
            <Route element={<PrivateRoute />}>
                <Route path="/" element={<Home />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/airtime" element={<Airtime />} />
                <Route path="/data" element={<Data />} />
                <Route path="/history" element={<History />} />
                <Route path='/topup' element={<TopUp />}></Route>
                <Route path='/tvs' element={<TVs />}></Route>
                <Route path='/tv-details' element={<TVDetails />}></Route>
                <Route path='/electric' element={<Electricity />}></Route>
                <Route path='/banktransfer' element={<BankTransfer />}></Route>
            </Route>

            {/* admin section */}
            <Route element={<AdminPrivateRoute />}>
                <Route path="admin" element={<Admim />} />
                <Route path="admin/users" element={<Users />} />
                <Route path="admin/banktransfer" element={<AdminTransfer />} />
                <Route path="admin/topup" element={<AdminTopup />} />
                <Route path="admin/all-transactions" element={<AllTransactions />} />
                <Route path="admin/settings" element={<Settings />} />
            </Route>
            <Route path='*' element={<NotFound />}></Route>

        </Routes>
    )
}

export default Router