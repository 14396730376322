import React, { useEffect, useState } from 'react'
import { GetAllTransaction } from '../../services/admin_service'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../../components/Loader'
import moment from 'moment'

const AllTransactions = () => {
    const [transactions, setTransactions] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getTransactions()
    }, [])

    const getTransactions = async () => {
        setLoading(true)
        await GetAllTransaction().then((el) => {
            setLoading(false)
            if (el.data.success) {
                setTransactions(el.data.returnObj)
            }
            console.log(el.data)
        }).catch(err => {
            setLoading(false)
            console.log(err.message)
            toast.error(err.message)
        })
    }


    return (
        <div className="panel-box panel-up">
            {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>}
            <div className="header-transfer header-st2">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex align-items-center">
                        <Link to={'/admin'} className="clear-panel"><i className="icon-left on_surface_color" /></Link>
                        <h3 className>All Transactions</h3>
                    </div>
                </div>
            </div>
            <div className="wrap-transfer-friends mt-3">
                <div className="tf-container">

                    <div style={{ marginTop: 50, marginBottom: 50 }}>

                        <div className="mt-4">
                            <ul className="tabs-list-item" style={{ display: 'block' }}>

                                {transactions?.map((el, index) => (
                                    <li key={index}>
                                        <a href="#" className="">
                                            <ul className="inner">
                                                <li className="user">
                                                    <img src="https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_list_48px-512.png" style={{ height: 30, width: 30 }} alt="image" />
                                                </li>
                                                <li className="info">
                                                    <h4>{el?.email}</h4>
                                                    <p>₦{el?.amount} {"."} <span>Date: {moment(el?.created_at).format("do MMM yyyy")}</span></p>

                                                </li>
                                            </ul>
                                        </a>
                                        <hr />
                                    </li>
                                ))}
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default AllTransactions