import axios from "axios";
import { BASE_URL, getUser } from "../config";

// const user = getUser();
const user = JSON.parse(window.localStorage.getItem('user'));
const header = {
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + user?.token
}

export const GetUsers = async () => {
    return await axios.get(`${BASE_URL}user`, {
        "headers": header
    })
}
export const GetTotalIncome = async () => {
    return await axios.get(`${BASE_URL}trans/get/total-income`, {
        "headers": header
    })
}

export const GetAllTransaction = async () => {
    return await axios.get(`${BASE_URL}trans/get-transactions`, {
        "headers": header
    })
}
export const UpdateUser = async (model) => {
    return await axios.put(`${BASE_URL}user/update/role`, model, {
        "headers": header
    })
}
export const UpdateWallet = async (model) => {
    return await axios.put(`${BASE_URL}user/update/wallet`, model, {
        "headers": header
    })
}

// export const UpdateRole = async (model) => {
//     return await axios.post(`${BASE_URL}update/role`, model, {
//         "headers": header
//     })
// }