import { useEffect } from 'react';
import Layout from './layouts/Layout'

function App() {

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    // alert("Complete Cache Cleared");
  };

  useEffect(() => {
    clearCacheData()
  }, [])


  return (
    <>
      <Layout />
    </>
  )
}

export default App
