import React, { useState } from 'react'
import { RegisterUser } from '../services/service';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const navivation = useNavigate();
    const [register, setRegister] = useState({
        name: '',
        email: '',
        password: '',
        phone: '',
        transactionPIN: '',
        role: 'client'
    });

    const [loading, setLoading] = useState(false)
    const [changeEye, setChangeEye] = useState(false)
    const [message, setMessage] = useState('')
    const handleChange = (e) => {
        setRegister({ ...register, [e.target.name]: e.target.value })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (register.transactionPIN === "0000" || register.transactionPIN === "1234") {
            setMessage("Your PIN is too weak")
            return;
        }
        RegisterUser(register).then((res) => {
            console.log(res)
            if (res.data.success) {
                navivation('/login')
                console.log(res.data);
            } else {
                setMessage(res.data.message)
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setMessage(err.message)
            console.error(err);
        })
    }
    return (
        <div>
            <div className="header">
                <div className="tf-container">
                    <div className="tf-statusbar br-none d-flex justify-content-center align-items-center">
                        <a href="/login" className="back-btn"> <i className="icon-left" /> </a>
                    </div>
                </div>
            </div>
            <div className="mt-3 register-section">
                <div className="tf-container">
                    <form className="tf-form" onSubmit={handleSubmit}>
                        {message && (<div className="alert alert-danger">{message}</div>)}
                        <h1>Register</h1>
                        <div className="group-input">
                            <label>Name</label>
                            <input type="text" value={register.name} placeholder='Your name...' name='name' onChange={handleChange} />
                        </div>
                        <div className="group-input">
                            <label>Email</label>
                            <input type="email" onChange={handleChange} value={register.email} name='email' placeholder="Example@gmail" />
                        </div>
                        <div className="group-input">
                            <label>Transaction PIN</label>
                            <input type="number" name='transactionPIN' onChange={handleChange} value={register.transactionPIN} placeholder='transaction PIN' />
                            <small className='text-warning'>Note: Transaction PIN must be 4 digit</small>
                        </div>
                        <div className="group-input">
                            <label>Phone Number</label>
                            <input type="text" name='phone' onChange={handleChange} value={register.phone} placeholder='Your Phone' />
                        </div>
                        <div className="group-input auth-pass-input last">
                            <label>Password</label>
                            <input type={`${changeEye ? 'text' : 'password'}`} placeholder="6-20 characters" name='password' onChange={handleChange} /> <a className="icon-eye password-addon" id="password-addon" onClick={() => setChangeEye(!changeEye)} />
                        </div>

                        {/* <div className="group-cb mt-5">
                            <input type="checkbox" defaultChecked className="tf-checkbox" />
                            <label className="fw_3">I agree to <a>Terms and condition</a> </label>
                        </div> */}
                        <button type="submit" className="tf-btn accent large">{loading ? 'Loading...' : 'Create An Account'}</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Register