import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { GetTotalIncome } from '../../services/admin_service';

const Admim = () => {
    const { user } = useSelector((state) => state.user)
    const { transInfo } = useSelector((state) => state.transInfo)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        getIncome()
    }, [])

    const getIncome = async () => {
        await GetTotalIncome().then((res) => {
            // console.log(res.data)
            setTotal(res.data.totalSum)
        }).catch(err => {
            // toast.error(err.message)
            console.log(err.message)
        })
    }

    if (user?.role !== 'admin') {
        window.location.href = '/login';
        return
    }
    return (
        <div style={{ marginBottom: 80 }}>
            <div className="app-header">
                <div className="tf-container">
                    <div className="tf-topbar d-flex justify-content-between align-items-center">
                        <a className="user-info d-flex justify-content-between align-items-center" href="/profile">
                            <img src="https://static-00.iconduck.com/assets.00/user-icon-2048x2048-ihoxz4vq.png" alt="image" />
                            <div className="content">
                                <h4 className="white_color">{user?.name}</h4>
                                <p className="white_color fw_4">Recharge made easy</p>
                            </div>
                        </a>
                        {/* <a href="#" id="btn-popup-up" className="icon-notification1"><span>2</span></a> */}
                    </div>
                </div>
            </div>
            <div className="card-secton">
                <div className="tf-container">
                    <div className="tf-balance-box">
                        <div className="balance">
                            <div className="row">
                                <div className="col-6 br-right">
                                    <div className="inner-left">
                                        <p>Total Income Balance:</p>
                                        <h3>₦ {total}</h3>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="inner-right">
                                        <p>-</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wallet-footer">
                            <ul className="d-flex justify-content-between align-items-center">
                                <li className="wallet-card-item">
                                    <Link to={'/admin/banktransfer'} className="fw_6 text-center" id="btn-popup-down">
                                        <ul className="icon icon-group-transfers">
                                            <li className="path1" />
                                            <li className="path2" />
                                            <li className="path3" />
                                        </ul>
                                        Topup Airtime / Data Wallet
                                    </Link>
                                </li>
                                <li className="wallet-card-item"><a className="fw_6" href="/admin/topup">
                                    <ul className="icon icon-topup">
                                        <li className="path1" />
                                        <li className="path2" />
                                        <li className="path3" />
                                        <li className="path4" />
                                    </ul>
                                    Topup TV / Electricity Wallet
                                </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <div className="tf-container">
                    <div className="tf-title d-flex justify-content-between">
                        <h3 className="fw_6">Services</h3>
                    </div>
                    <ul className="box-service mt-3">
                        <li>
                            <Link to={'/admin/users'}>
                                <div className="icon-box bg_color_1">
                                    <img src="https://www.iconpacks.net/icons/1/free-user-group-icon-296-thumb.png" alt="" />
                                </div>
                                All Users
                            </Link>
                        </li>
                        <li>
                            <Link to={'/admin/all-transactions'}>
                                <div className="icon-box bg_color_2">
                                    <img src="https://cdn-icons-png.flaticon.com/512/8552/8552832.png" alt="" />
                                </div>
                                All Transactions
                            </Link>
                        </li>


                        <li>
                            <a href="admin/settings">
                                <div className="icon-box bg_color_3">
                                    <img src="https://icon-library.com/images/android-settings-icon-png/android-settings-icon-png-21.jpg" alt="" />
                                </div>
                                Settings
                            </a>
                        </li>

                    </ul>
                </div>
            </div>

            {/* navigation */}



        </div>
    )
}

export default Admim