import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { GetSingleHistory } from '../services/service'
import { ToastContainer, toast } from 'react-toastify'
import { useSelector } from 'react-redux'

const History = () => {
    const { user } = useSelector((state) => state.user)
    const [loading, setLoading] = useState(false)
    const [histories, setHistories] = useState([])
    useEffect(() => {
        getHistory()
    }, [])

    const getHistory = () => {
        setLoading(true)
        let model = {
            email: user?.email
        }
        GetSingleHistory(model)
            .then((res) => {
                setHistories(res.data?.returnObj)
                // console.log("history", res.data)
                setLoading(false)
            })
            .catch(err => {
                toast.error(err.message)
                setLoading(false)
            })
    }

    return (
        <div>
            <div className="header is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <a href="/" className="back-btn"> <i className="icon-left" /> </a>
                        <h3>History</h3>

                    </div>
                </div>
            </div>
            <div id="app-wrap">
                <div className="app-section st1 mt-1 bg_white_color">
                    <div className="tf-container">
                        <div className="wrap-total">
                            <div className="total-item">
                                <a href="#" className="box-icon bg_primary"><i className="icon-arrow-up_minor primary_color" /></a>
                                <div className="content">
                                    <p className="fw_4">In</p>
                                    <h2 className="fw_6 success_color">₦</h2>
                                </div>
                            </div>
                            <div className="total-item">
                                <a href="#" className="box-icon bg_critical"><i className="icon-arrow-up_minor critical_color arrow-down" /></a>
                                <div className="content">
                                    <p className="fw_4">Out</p>
                                    <h2 className="fw_6 critical_color">₦</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="app-section st1 mt-1 mb-5 bg_white_color">
                    <div className="tf-container">
                        <div className="trading-month">
                            <h4 className="fw_5 mb-3">---</h4>
                            <div className="group-trading-history mb-5">
                                {histories?.map((el, index) => (
                                    <a className="tf-trading-history" href="#" key={index}>
                                        <div className="inner-left">
                                            <div className="icon-box rgba_primary">
                                                <i className="icon icon-wallet-1" />
                                            </div>
                                            <div className="content">
                                                <h4>{el?.whatFor}</h4>
                                                <p>{moment(el?.createdAt).format('MMMM Do YYYY, h:mm a')}</p>
                                            </div>
                                        </div>
                                        <span className={`num-val ${el?.isIncome ? 'success_color' : 'critical_color'}`}> ₦{el?.amount}</span>
                                    </a>
                                ))}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default History