import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Forgot_Password } from '../../services/service';


const ConfirmOtp = () => {

    const location = useLocation()
    const navigation = useNavigate();
    const { data } = location.state;
    // console.log(data)
    const [otp, setOtp] = useState('')
    const [compareOtp, setCompareOtp] = useState(data?.response.otp)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {
        setLoading(true)

        if (otp == compareOtp) {
            navigation('/reset-password', {
                state: { obj: data?.email }
            })
        }
        else {
            setMessage("OTP is wrong")
        }
        setLoading(false);

    }

    const [loadRequest, setLoadRequest] = useState(false)
    const handleRetry = () => {
        setLoadRequest(true)
        let model = {
            email: data?.email
        }
        Forgot_Password(model).then((res) => {
            if (res.data.status) {
                toast.success("OTP has been sent to your mail")
                setCompareOtp(parseInt(res.data.returnObj.otp))

            } else {
                toast.error("Error occured! please try again")
            }
            setLoadRequest(false)
        }).catch(err => {
            setLoadRequest(false)
            toast.error(err.message)
        })

    }

    return (
        <div className="mt-7 login-section">
            <div className="tf-container">
                <h1>Confirm your OTP</h1>
                {message && (<div className='alert alert-danger'>{message}</div>)}
                <div className="group-input">
                    <label>Please enter the OTP sent to your email</label>
                    <input type="number" onChange={(e) => setOtp(e.target.value)} placeholder="12234" />
                </div>

                <button type="button" onClick={handleSubmit} className="tf-btn accent large">{loading ? 'Loading...' : 'Send'}</button>
                <p>Didn't get mail? <a style={{ cursor: 'pointer', color: 'orange' }} onClick={handleRetry}>{loadRequest ? 'Requesting...' : 'Retry OTP'}</a></p>

                <p className="mb-9 fw-3 text-center ">Already have a Account? <a href="/login" className="auth-link-rg">Login</a></p>
            </div>
            <ToastContainer />
        </div>
    )
}


export default ConfirmOtp