import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GetBalance, LoginUser } from '../services/service'
import { loginUser, setCharges } from '../store/userSlice'

const Login = () => {
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const [changeEye, setChangeEye] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setMessage] = useState("")
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    })


    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault()

        LoginUser(loginData).then((res) => {
            setLoading(false)
            if (res.data.success) {

                let response = {
                    token: res?.data.returnObj.token,
                    role: res?.data.returnObj.role,
                    email: res?.data.returnObj.email,
                    name: res?.data.returnObj.name
                }
                // console.log(response)
                window.localStorage.setItem('user', JSON.stringify(response));
                window.localStorage.setItem('charges', JSON.stringify(res.data.returnObj.datacharges));

                dispatch(loginUser(response))
                dispatch(setCharges(res?.data.returnObj.datacharges))
                navigation('/')
            } else {
                setMessage(res.data.message)
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setMessage(err.response?.data.message)
            console.error(err);
        })

    }
    const handleInput = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value })
    }
    return (
        <div className="mt-7 login-section">
            <div className="tf-container">
                <form className="tf-form" onSubmit={handleSubmit}>
                    <h1>Login</h1>
                    {error && (<div className='alert alert-danger'>{error}</div>)}
                    <div className="group-input">
                        <label>Email</label>
                        <input type="email" name='email' onChange={handleInput} value={loginData.email} placeholder="Example@gmail" />
                    </div>
                    <div className="group-input auth-pass-input last">
                        <label>Password</label>
                        <input type={`${changeEye ? 'text' : 'password'}`} className="password-input" placeholder="Password" name='password' onChange={handleInput} value={loginData.password} />
                        <a className="icon-eye password-addon" id="password-addon" onClick={() => setChangeEye(!changeEye)} />
                    </div>
                    <a href="/forgot-password" className="auth-forgot-password mt-3">Forgot Password?</a>
                    <button type="submit" className="tf-btn accent large">{loading ? 'Loading...' : 'Log In'}</button>
                </form>
                <p className="auth-forgot-password mt-3">For Enquiries / Complaint please contact us on <a href='tel:08167927876' className="auth-link-rg">08167927876</a></p>

                <p className="mb-9 fw-3 text-center ">Not yet a user? <a href="/register" className="auth-link-rg">Sign up Now!</a></p>
            </div>
        </div>
    )
}

export default Login