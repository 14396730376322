import React, { useEffect } from 'react'
import Router from '../route/Route'
import Footer from '../components/navigation/Footer'
import { GetAdInfo } from '../services/service'
import { updateExpireTime } from '../components/WebActivity'

const Layout = () => {

    const checkForInactivity = () => {
        const expTime = localStorage.getItem('expireTime');

        if (expTime < Date.now()) {
            console.log('logout');
            localStorage.clear()
            window.location.reload();
        }
    }

    useEffect(() => {
        updateExpireTime()
        //set evet listern
        window.addEventListener("click", updateExpireTime)
        window.addEventListener("keypress", updateExpireTime)
        window.addEventListener("scroll", updateExpireTime)
        window.addEventListener("mousemove", updateExpireTime)

        // clean up
        return () => {
            window.removeEventListener("click", updateExpireTime)
            window.removeEventListener("keypress", updateExpireTime)
            window.removeEventListener("scroll", updateExpireTime)
            window.removeEventListener("mousemove", updateExpireTime)
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            checkForInactivity()
        }, 86400000);
        //clear
        return () => clearInterval(interval)
    }, [])
    return (
        <>
            <Router />
            <Footer />
        </>
    )
}

export default Layout